import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormData from "form-data";
import Swal from "sweetalert2";
import moment from "moment";
import { DecryptED } from "../DecryptJS/Decrypt";
import { defaultUrl, ReplaceUrl } from "./util";

const Profiledeatils = () => {
  let REACT_LOCAL_BASE_URL = "http://localhost:8080";
  const [data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const { sid, userid } = useParams();
  const [schoolName, setSchoolName] = useState("");
  const [acadYear, setAcadYear] = useState("");
  const [render, setRender] = useState(false);
  const [columns, setColumn] = useState({});
  const [imageInfo, setImageInfo] = useState({
    file: [],
    filepreview: null,
  });

  // image resize
  const handleImageChange = (event) => {
    let reader = new FileReader();
    const imageFile = event.target.files[0];
    const imageFilname = event.target.files[0].name;

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        //------------- Resize img code ----------------------------------
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var MAX_WIDTH = 700;
        var MAX_HEIGHT = 700;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], imageFilname, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setImageInfo({
              ...imageInfo,
              file: file,
              filepreview: URL.createObjectURL(imageFile),
            });
          },
          "image/jpeg",
          1
        );
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  const handleImageSubmit = async (e, pictype, tablename) => {
    // console.log(pictype);
    e.preventDefault();
    let bucketName = `topschool-attachments/${schoolName}prisms/${acadYear}/studentProfile/${pictype.toLowerCase()}picture`;
    let formData = new FormData();
    formData.append("sid", DecryptED(sid));
    formData.append("user_id", DecryptED(userid));
    formData.append("tablename", tablename);
    formData.append("bucketName", bucketName);
    formData.append("profile", `${pictype.toLowerCase()}`);
    // formData.append("image", image);
    formData.append("image", imageInfo.file);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/api/information/changepic`,
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Image updated successfully!`,
          showConfirmButton: false,
          timer: 1500,
        });
        // window.location.reload();
        setRender(!render);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      });
  };

  const capitalize = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const handleChangeX = (e, type) => {
    let val = e.target.value;
    setColumn((columns) => ({
      ...columns,
      [type]: val,
    }));
  };

  const updateData = async (e, tablename) => {
    const data = {
      sid: DecryptED(sid),
      user_id: DecryptED(userid),
      tablename: tablename,
      columns: columns,
    };
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/api/information/update`,
      data,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
      },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Email update successfully!`,
          showConfirmButton: false,
          timer: 500,
        });
        setRender(!render);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      });
  };
  useEffect(() => {
    const data = {
      sid: DecryptED(sid),
      userid: DecryptED(userid),
    };
    const getStudentdetails = async () => {
      try {
        setLoader(true);
        const response = await axios({
          method: "post",
          data: data,
          url: `${process.env.REACT_APP_BASE_URL}/api/studentProfile/data`,
        });
        setData(response.data);
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getStudentdetails();

    const getAcadId = async () => {
      const data = {
        sid: DecryptED(sid),
      };
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/tscommon/getCurrentAcad",
        data,
        headers: {
          Authorization: "#$laksdfnlknoea#$@$%^&%$",
        },
      }).then(async (response) => {
        setAcadYear(response.data[0].academics);
      });
    };
    getAcadId();

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid,
      };
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then((response) => {
        setSchoolName(response.data);
      });
    };
    getSchoolName();
  }, [render]);

  // console.log(Loader);
  return (
    <Container>
      {Loader ? (
        <h1
          style={{
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          ...
        </h1>
      ) : (
        <Wrapper>
          <div className="Profile__details">
            <div className="profile__img">
              {data &&
                data.map((item, idx) => {
                  return (
                    <div className="profile__pic" key={idx}>
                      {item.avatar ? (
                        <img src={ReplaceUrl(item.avatar)} alt={" "} />
                      ) : (
                        <img src={defaultUrl} alt={""} />
                      )}
                    </div>
                  );
                })}
            </div>

            {/* General Details */}
            {data.map((item, idx) => {
              return (
                <div className="General__details" key={idx}>
                  <h2
                    style={{
                      fontFamily: "Poppins",
                    }}
                    className="tg"
                  >
                    General Details
                  </h2>
                  <Details
                    title={"Name"}
                    value={capitalize(item.Student_name)}
                  />
                  <Details title={"Username"} value={item.username} />
                  <Details
                    title={"Class"}
                    value={item.class_name ? item.class_name : null}
                  />
                  <Details title={"Batch"} value={acadYear} />
                </div>
              );
            })}

            {/* Personal Details */}
            {data.map((item, idx) => {
              return (
                <div className="personal__details" key={idx}>
                  <TitleComponent val={"Personal Details"} />
                  <Details
                    title={"Date of birth"}
                    value={moment(item.dob).format("DD-MM-YYYY")}
                  />
                  <Details title={"Father's Name"} value={item.father_name} />

                  <Details title={"Mother's Name"} value={item.mother_name} />

                  <AvatarUpload
                    title={"Student Pic"}
                    value={item.avatar}
                    url={ReplaceUrl(item.avatar)}
                    avatarUrl={"avatar"}
                    handleImageSubmit={handleImageSubmit}
                    handleImageChange={handleImageChange}
                    tablename={"k6kb9_pri_students_static_info"}
                  />
                  <AvatarUpload
                    title={"Father's Pic"}
                    value={item.app_avatar_father}
                    url={ReplaceUrl(item.app_avatar_father)}
                    handleImageSubmit={handleImageSubmit}
                    handleImageChange={handleImageChange}
                    avatarUrl={"app_avatar_father"}
                    tablename={"k6kb9_pri_parents_info"}
                  />
                  <AvatarUpload
                    title={"Mother's Pic"}
                    value={item.app_avatar_mother}
                    url={ReplaceUrl(item.app_avatar_mother)}
                    handleImageSubmit={handleImageSubmit}
                    handleImageChange={handleImageChange}
                    avatarUrl={"app_avatar_mother"}
                    tablename={"k6kb9_pri_parents_info"}
                  />
                </div>
              );
            })}

            {/* Address */}
            {data.map((item, idx) => {
              return (
                <div className="General__details" key={idx}>
                  <TitleComponent val={"Address"} />
                  <Details title={"Address line 1"} value={item.paddressl1} />
                  <Details title={"Address line 2"} value={item.paddressl2} />
                  <Details title={"State"} value={item.pstate} />
                  <Details title={"City"} value={item.pcity} />
                  <Details title={"Pin"} value={item.ppin} />
                </div>
              );
            })}
            {/* Contact */}
            {data.map((item, idx) => {
              return (
                <div className="General__details" key={idx}>
                  <TitleComponent val={"Contact"} />
                  <Details title={"Mobile 1"} value={item.mobile1} />
                  <Details title={"Mobile 2"} value={item.mobile2} />

                  <EmailUpdate
                    title={`Father's Email`}
                    value={item.email}
                    etype={"email"}
                    handleChangeX={handleChangeX}
                    tablename={"k6kb9_pri_parents_info"}
                    updateData={updateData}
                  />
                  <EmailUpdate
                    title={`Mother's Email`}
                    value={item.memail}
                    etype={"memail"}
                    handleChangeX={handleChangeX}
                    tablename={"k6kb9_pri_parents_info"}
                    updateData={updateData}
                  />
                  <EmailUpdate
                    title={`Student's Email`}
                    value={item.studemail}
                    etype={"studemail"}
                    handleChangeX={handleChangeX}
                    tablename={"k6kb9_pri_students_static_info"}
                    updateData={updateData}
                  />
                </div>
              );
            })}
          </div>
        </Wrapper>
      )}
    </Container>
  );
};

const TitleComponent = ({ val }) => {
  return (
    <h2
      style={{
        fontFamily: "Poppins",
      }}
      className="tp"
    >
      {val}
    </h2>
  );
};

const Details = ({ title, value }) => {
  return (
    <>
      <div className="details">
        <div>
          <span
            style={{
              fontFamily: "Poppins",
            }}
            className="title__span"
          >
            {title}
          </span>
        </div>
        <div className="title_2">
          <span
            span
            style={{
              fontFamily: "Poppins",
            }}
            className="title__span"
          >
            {value}
          </span>
        </div>
      </div>
    </>
  );
};

const AvatarUpload = ({
  title,
  value,
  url,
  avatarUrl,
  tablename,
  handleImageSubmit,
  handleImageChange,
}) => {
  return (
    <div className="details">
      <div>
        <span
          style={{
            fontFamily: "Poppins",
          }}
          className="title__span"
        >
          {title}
        </span>
      </div>
      <div className="title_2">
        {value ? (
          <img className="img_2" src={url} alt="" />
        ) : (
          <img className="img_2" src={defaultUrl} alt="" />
        )}
        <div className="uploadDiv">
          <form
            className="input__form"
          >
            <input
              className="uploadInputStyle"
              type="file"
              name="filename"
              id="myFile"
              onChange={(e) => handleImageChange(e)}
            />
            <input
              onClick={(e) =>
                handleImageSubmit(e, avatarUrl, tablename)
              }
              value="Upload"
              className="uploadBtn"
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

const EmailUpdate = ({
  title,
  value,
  handleChangeX,
  etype,
  updateData,
  tablename,
}) => {
  return (
    <div className="details">
      <div>
        <span
          style={{
            fontFamily: "Poppins",
          }}
          className="title__span"
        >
          {title}
        </span>
      </div>
      <div className="title_3">
        <input
          className="inputStyle"
          placeholder={value}
          onChange={(e) => handleChangeX(e, etype)}
        />

        <label className="container">
          <input type="checkbox" onClick={(e) => updateData(e, tablename)} />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  max-width: 768px;
  /* min-width: 500px; */
  /* min-width: 480px; */
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    height: 100vh;
  }

  @media (max-width: 768px) {
    padding: 0;
    box-shadow: none;
    margin: 0 auto;
    box-shadow: none;
    height: 100vh;
  }
  .Profile__details {
    /* max-width: 650px;
    min-width: 480px; */
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      padding: 0;
      box-shadow: none;
      margin: 0 auto;
      height: 100vh;
    }

    .profile__img {
      background-color: #1d31c6;
      height: 250px;
      width: 100%;

      .not_found {
        font-size: 2.3rem;
        color: #fff;
      }

      .st__name {
        position: relative;
        top: 354px;
        margin-left: 39%;
        font-size: 1.4rem;
        font-weight: 600;
        color: #222;

        @media (max-width: 595px) {
          top: 330px;
          margin-left: 30%;
        }
      }
      .profile__pic {
        img {
          position: absolute;
          width: 140px;
          height: 140px;
          top: 155px;
          left: 34%;
          border-radius: 100px;

          @media (max-width: 595px) {
            height: 140px;
            width: 140px;
          }
        }
      }
      .main__heading {
        text-align: start;
        font-size: 3.5rem;
        font-weight: 900;
        color: #fff;
        margin-left: 12px;
      }
    }

    .tg {
      margin-top: 150px;
      margin-left: 20px;
      font-size: 1.5rem;
      font-weight: 600;
      color: #4b82e6;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .tp {
      margin-top: 30px;
      margin-left: 20px;
      font-size: 1.5rem;
      font-weight: 600;
      color: #4b82e6;

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }

    .details {
      display: flex;
      width: 92%;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      font-size: 1.3rem;

      @media (max-width: 768px) {
        margin-left: 20px;
        font-size: 1rem;
      }

      .img_2 {
        width: 120px;
        height: 120px;

        object-fit: cover;
        border-radius: 100px;
      }
      .title_2 {
        width: 200px;
        position: relative;
        // text-align: start;

        @media (min-width: 300px) and (max-width: 500px) {
          width: 180px;
          position: relative;
        }

        .uplaodDiv {
          width: 100%;
          display: flex;
        }
        .input__form {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-left: -41px;

          @media (min-width: 300px) and (max-width: 500px) {
            margin-left: -9px;
          }
        }
        .uploadInputStyle {
          width: 38%;
          outline: none;
          border: none;
          font-size: 10px;
          background-color: rgb(75, 130, 230);
          background: #fff;
          border: 2px solid rgb(75, 130, 230);
          border-radius: 0px;
        }
        .uploadBtn {
          width: 80px;
          font-size: 14px;
          font-weight: 500;
          color: white;
          background-color: rgb(75, 130, 230);
          border: none;
          outline: none;
          cursor: pointer;
          border-radius: 0px;
        }
        .uploadBtn:hover {
          background-color: rgb(84, 26, 220);
        }
        input {
          .inputStyle {
            background-color: rgb(75, 130, 230);
            color: white;
            width: 215px;
            font-size: 16px;
            border: none;
            outline: none;
            padding: 7px 10px;
            border-radius: 4px;
          }
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          margin-left: 226px;
          top: -38px;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: -8px;
          left: 0;
          height: 35px;
          width: 35px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .title_3 {
        width: 220px;
        position: relative;

        @media (max-width: 768px) {
          width: 190px;
          margin-right: 62px;
          position: relative;
        }

        input {
          .inputStyle {
            background-color: rgb(75, 130, 230);
            color: white;
            width: 215px;
            font-size: 16px;
            border: none;
            outline: none;
            padding: 7px 10px;
            border-radius: 4px;
          }
          @media (max-width: 768px) {
            width: 110%;
            font-size: 13px;
          }
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          margin-left: 226px;
          top: -38px;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          @media (max-width: 768px) {
            margin-left: 212px;
          }
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: -8px;
          left: 0;
          height: 35px;
          width: 35px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
`;
export default Profiledeatils;
