import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import moment from "moment";
import Swal from "sweetalert2";
import "./Selectdaterange";
import * as XLSX from "xlsx";

export default function Tickets(props) {
  const history = useHistory();
  const [excellData, setExcelData] = useState("");
  const { sid, uid } = useParams();
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [filter, setFilter] = useState("status");
  const [filterSelect, setFilterSelect] = useState("Open");
  const [data, setData] = useState([]);
  const [odata, setOData] = useState([]);
  const [filterloader, setfilterLoader] = useState(true);
  const [apiloader, setapiLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    document.getElementsByTagName("title")[0].innerHTML = "Ticketing System";
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "AUserInfo",
        sid: sid,
        u_id: uid,
      })
      .then((res) => {
        setName(
          res.data !== undefined
            ? res.data.student !== undefined && res.data.student.length > 0
              ? res.data.student[0].name || "Unknown"
              : "Unknown"
            : "Unknown"
        );
      });
    props.userType === "superadmin"
      ? SuperAdminGetTickets()
      : getTicketCreated(sid);
  }, []);

  useEffect(() => {
    if (filter !== "") {
      setData(
        odata.filter((d) => {
          if (filter === "status")
            return d[filter].toString().indexOf(filterSelect) !== -1;
          if (typeof d[filter] === "string")
            return d[filter].toLowerCase().indexOf(search.toLowerCase()) !== -1;
          else return d[filter].toString().indexOf(search) !== -1;
        })
      );
    } else {
      setData(odata);
    }
    setfilterLoader(false);
  }, [search, odata, filterSelect]);

  function getTicketCreated(id) {
    let withuid = props.userType === "user" ? { staff_id: uid } : { all: 1 };
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "GetTicketCreate",
        sid: id,
        ...withuid,
      })
      .then((res) => {
        setOData(res.data.Tickets);
        setapiLoader(false);
      });
  }
  function SuperAdminGetTickets() {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        fun_name: "Appointment_Superadmin",
        sid: sid,
      })
      .then(async (res) => {
        if (
          !res.data ||
          (res.data !== undefined ? res.data.slist.length === 0 : false) ||
          typeof res.data !== "object"
        ) {
          getTicketCreated(sid);
        } else {
          const response = await axios.all(
            res.data.slist.map((s) =>
              axios.post(
                "https://topschool.prisms.in/rest/index.php/user_list.json?",
                { fun_name: "GetTicketCreate", sid: s.sid }
              )
            )
          );
          const data = response.map((s) => {
            if (s.data) {
              console.log(s.data);
              if (s.data.Tickets) {
                if (typeof s.data.Tickets === "object") {
                  console.log(s.data.Tickets);
                  return s.data.Tickets;
                }
              }
            }
            return [];
          });
          setOData(
            data.flat().sort((a, b) => {
              if (
                moment(a.updated_on, "YYYY-MM-DD HH:mm:ss").format("x") >
                moment(b.updated_on, "YYYY-MM-DD HH:mm:ss").format("x")
              )
                return -1;
              if (
                moment(a.updated_on, "YYYY-MM-DD HH:mm:ss").format("x") <
                moment(b.updated_on, "YYYY-MM-DD HH:mm:ss").format("x")
              )
                return 1;
              return 0;
            })
          );
          setapiLoader(false);
        }
      });
  }

  function updateRemark(data, remark) {
    var d = data;
    delete d.duedate1;
    const d1 = { ...d, remark: remark };
    const fm = new FormData();
    Object.keys(d1).map((i) => {
      if (i !== "duedate") {
        fm.append(i === "school" ? "schoolname" : i, d1[i]);
      }
    });
    fm.append("fun_name", "Update_Ticket_Create");
    fm.append("sid", sid);

    setapiLoader(true);
    setfilterLoader(true);
    const nam = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    if (
      d.remark !== "" &&
      new Date(data.duedate).getTime() >=
        new Date(
          moment()
            .add(1, "days")
            .format("YYYY-MM-DD HH:mm:ss")
        ).getTime()
    ) {
      fm.append("duedate", data.duedate);

      const updateTicketHistory = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        {
          fun_name: "Update_Ticket_History",
          sid: sid,
          ticket_id: data.id,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          committee: data.committee,
          remark_reply: remark,
          reply: 0,
          staff: nam,
          status: data.status,
          history: `${nam} remarked on ticket`,
          history_details: `${nam} remarked ticket as ${remark}`,
          uid: uid,
          is_sadmin: props.userType === "superadmin" ? 1 : 0,
        }
      );
      const updateTicketCreated = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        fm
      );

      axios.all([updateTicketHistory, updateTicketCreated]).then(() => {
        getTicketCreated(sid);
      });
    }

    if (
      d.remark === "" ||
      new Date(data.duedate).getTime() <
        new Date(
          moment()
            .add(1, "days")
            .format("YYYY-MM-DD HH:mm:ss")
        ).getTime()
    ) {
      fm.append(
        "duedate",
        moment()
          .add(4, "days")
          .format("YYYY-MM-DD HH:mm:ss")
      );

      const insertTicketHistory = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        {
          fun_name: "TicketHistoryInsert",
          sid: sid,
          ticket_id: data.id,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          committee: data.committee,
          remark_reply: remark,
          reply: 0,
          staff: nam,
          status: data.status,
          history: `${nam} remarked on ticket`,
          history_details: `${nam} remarked ticket as ${remark}`,
          uid: uid,
          is_sadmin: props.userType === "superadmin" ? 1 : 0,
        }
      );
      const updateTicketCreated = axios.post(
        "https://topschool.prisms.in/rest/index.php/user_list.json?",
        fm
      );

      axios.all([insertTicketHistory, updateTicketCreated]).then(() => {
        getTicketCreated(sid);
      });
    }
  }

  function Push_TO_TicketReply(id, s_id) {
    props.userType === "superadmin"
      ? parseInt(s_id) > 0
        ? history.push(
            `/tickets/${props.userType}/details/${sid}/${s_id}/${uid}/${id}`,
            {
              id: id,
              username:
                name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
              userType: props.userType,
              title: "Ticket Details",
            }
          )
        : Swal.fire("Error", "Sorry, There is a issue!", "error")
      : history.push(`/tickets/${props.userType}/details/${sid}/${uid}/${id}`, {
          id: id,
          username: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
          userType: props.userType,
          title: "Ticket Details",
        });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      const newData = data.filter((item) => {
        return item.date >= startDate && item.date < endDate;
      });
      setData(newData);
    }
  };
  
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(excellData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "report.xlsx");
 };

 useEffect(() => {
   if (data && data.length > 0) {
     const mappedData = data.map((item) => ({
       "Sr No": item.id,
       "Created On": moment(item.created_on).format("DD-MM-YYYY HH:mm:ss"),
       "Ticket Status": item.status,
       'Committe / Department': item.committee,
       "Name of Ticket Creator": item.name,
       "Assigned to": item.staff,
       Title: item.title,
       'Ticket No':item.id,
       Description: item.description,
       Remark: item.remark,
       "School Name": item.school,
       "Upated on": moment(item.updated_on).format("DD-MM-YYYY HH:mm:ss"),
       "Due date": moment(item.duedate).format("DD-MM-YYYY"),
       Priority: item.priority,

       // date: moment(item.date).format('DD-MM-YYYY'),
       // contact: item.contact,
       // created_on: moment(item.created_on).format('DD-MM-YYYY HH:mm:ss'),
     }));
     setExcelData(mappedData);
   }
 }, [data]);

  return (
    <>
      <div
        className="totalTicketingModule"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <Header
          title={props.title}
          options={props.options}
          ChangePage={(params) =>
            history.push(
              params.data !== undefined ? params.url : params.url,
              params.data
            )
          }
        />
        {name ? (
          <div className="user__name">
            <span>Welcome {name}</span>
          </div>
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="TMDashboardMainDiv">
            <div className="TMDashboardSubDiv">
              <div className="TMDashboardOptions">
                <div className="TMAdminFilterDiv">
                  {(data === undefined || data.length < 1) &&
                  filter !== "status" &&
                  search === "" ? (
                    ""
                  ) : (
                    <select
                      onChange={(e) => {
                        setSearch("");
                        setFilter(e.target.value);
                      }}
                      defaultValue={filter}
                    >
                      <option value="status">Filter by Status</option>
                      <option value="committee">
                        Filter by Committee/Department
                      </option>
                      <option value="staff">Filters by Staff</option>
                      <option value="title">Filters by Title</option>
                      <option value="duedate">Filters by DueDate</option>
                      <option value="date">Filters by Date</option>
                      <option value="id">Filters by Ticket Number</option>
                      <option value="remark">Filters by Remark</option>
                      <option value="school">Filters by School</option>
                      <option value="name">Filters by Name</option>
                    </select>
                  )}
                </div>

                <div className="TMAFilterbox">
                  {search === "" &&
                  filter !== "status" &&
                  (data === undefined || data.length < 1) ? (
                    ""
                  ) : filter !== "status" ? (
                    // custom date range
                    filter === "date" ? (
                      <div className="custom__date_range">
                        <input
                          className="custom__input_date"
                          type="date"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                        <input
                          className="custom__input_date"
                          type="date"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                        <button
                          className="custom__date_btn"
                          onClick={(e) => onSubmit(e)}
                        >
                          submit
                        </button>
                      </div>
                    ) : (
                      <input
                        type={filter === "duedate" ? "date" : "text"}
                        className={"TMAdminSearch"}
                        defaultValue={search}
                        onChange={(e) => {
                          if (filter === "") {
                            alert("Please select any filter");
                          } else setSearch(e.target.value);
                        }}
                        onClick={() => {
                          if (filter === "") {
                            alert("Please select any filter");
                          }
                        }}
                        placeholder={"Quick Search"}
                      />
                    )
                  ) : (
                    <select
                      className={"TMAdminSearch"}
                      onChange={(e) => {
                        setFilterSelect(e.target.value);
                      }}
                    >
                      <option value="Open" label="Open" />
                      <option value="Closed" label="Closed" />
                      <option value="" label="All" />
                    </select>
                  )}
                  {/* Show numbers of All, open and closed tickets '|| filter === "date"' */}
                  {(filter === "status" || filter === "date") &&
                  (filterSelect === "Open" ||
                    filterSelect === "Closed" ||
                    filterSelect === "") ? (
                    <div className="count__status__admin">
                      <span>
                        {filterSelect ? filterSelect : "All"} {data.length}
                      </span>
                    </div>
                  ) : null}
                </div>

                <div>
                  <button
                    style={{
                      border: "none",
                      padding: "10px 36px",
                      fontWeight: "700",
                      backgroundccolor: "#448fc5",
                    }}
                    onClick={downloadExcel}
                  >
                    Download Excel Report
                  </button>
                </div>
              </div>
            </div>
            <div className="TMAdminDashboardDetailsMainDiv">
              {filterloader || apiloader || name === "" ? (
                <div className="TMLoader">
                  <Loader type="Puff" color="#1e5597" height={70} width={70} />
                </div>
              ) : (
                <div className="TMAdminDashboard__InnerMain">
                  <div className="TMAdminDashboard__InnerMain">
                    <div>
                      <div style={{ marginBottom: "150px", marginTop: "30px" }}>
                        {data.map((i, k) => {
                          return (
                            <div className="TMAdminDashboradDetailsDiv" key={k}>
                              <div className="TMAdminDeatilsBox">
                                <div
                                  className="TMAdminDashDBox"
                                  onClick={() => {
                                    Push_TO_TicketReply(i.id, i.s_id);
                                  }}
                                >
                                  <span className="TMAdminTitle">
                                    <span className="TMAdminTicketNo">
                                      #{i.id}
                                    </span>{" "}
                                    {i.title}
                                  </span>
                                  <span
                                    style={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <span>
                                      <img
                                        src="/icons/person.png"
                                        style={{ width: "20px" }}
                                      />
                                      &nbsp;{i.name}
                                    </span>
                                    <span>
                                      <img
                                        src="/icons/school.png"
                                        style={{
                                          width: "20px",
                                          marginBottom: "-2.5px",
                                          marginLeft: "20px",
                                        }}
                                      />
                                      &nbsp;{i.school}
                                    </span>
                                    <span>
                                      <img
                                        src="/icons/audience.png"
                                        style={{
                                          width: "20px",
                                          marginBottom: "-5px",
                                          marginLeft: "20px",
                                        }}
                                      />
                                      &nbsp;{i.committee}
                                    </span>
                                    <span>
                                      <img
                                        src="/icons/clock.png"
                                        style={{
                                          width: "20px",
                                          marginBottom: "-5px",
                                          marginLeft: "20px",
                                        }}
                                      />
                                      &nbsp;
                                      {moment(
                                        i.date,
                                        "YYYY-MM-DD HH:mm:ss"
                                      ).format("DD-MM-YYYY")}
                                    </span>
                                  </span>
                                </div>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    className="TMAdminDashboardStatus"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      Push_TO_TicketReply(i.id, i.s_id);
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#d3ffeb",
                                        marginBottom: "10px",
                                        width: "100px",
                                        padding: "7px",
                                        textAlign: "center",
                                        borderRadius: "25px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {" "}
                                      {i.duedate1.split(" ")[0]}{" "}
                                      {i.duedate1.split(" ")[1].slice(0, 3)}
                                    </div>
                                  </div>
                                  <div
                                    className="TMAdminDashboardStatus"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      Push_TO_TicketReply(i.id, i.s_id);
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          i.priority === "High"
                                            ? "#ffc9c9"
                                            : i.priority === "Medium"
                                            ? "#fffd88"
                                            : "#befec7",
                                        marginBottom: "10px",
                                        width: "100px",
                                        padding: "7px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      {" "}
                                      {i.priority}
                                    </div>
                                  </div>
                                  <div
                                    className="TMAdminDashboardStatus"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      Push_TO_TicketReply(i.id, i.s_id);
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          i.status === "Open"
                                            ? "#ffc9c9"
                                            : "#befec7",
                                        width: "100px",
                                        padding: "7px",
                                        marginBottom: "10px",
                                        textAlign: "center",
                                        fontWeight: "500",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      {" "}
                                      {i.status}
                                    </div>
                                  </div>
                                  <div className="TMAdminRemarkSelect TMAdminDashboardStatus">
                                    <select
                                      onChange={(e) => {
                                        if (
                                          e.target.value !== "" &&
                                          parseInt(i.s_id) > 0
                                        ) {
                                          updateRemark(i, e.target.value);
                                        } else {
                                          e.target.value = i.remark;
                                        }
                                      }}
                                      defaultValue={i.remark}
                                    >
                                      <option value="">Remark</option>
                                      <option value="Checking Query">
                                        Checking Query
                                      </option>
                                      <option value="On Leave">On Leave</option>
                                    </select>
                                  </div>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {data === undefined || data.length < 1 ? (
                    <div
                      className="TMAD__No_ReportsTitle"
                      style={{
                        marginBottom: "150px",
                        marginTop: "150px",
                        width: "100%",
                      }}
                    >
                      <div className="TMAdminDashboradDetailsDiv">
                        <div
                          className="TMAdminDeatilsBox"
                          style={{
                            display: "inline",
                            textAlign: "center",
                            padding: "15px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#022a79",
                            border: "0px",
                            boxShadow: "none",
                          }}
                        >
                          No Reports
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {/* <Pagination /> */}
        </div>
      </div>
    </>
  );
}
