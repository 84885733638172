import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuItem, InputLabel, FormControl, Select } from "@material-ui/core";
import UpdateD from "./UpdateD";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  formControl: {
    width: 120,
    marginTop: 5,
  },
}));
function range(start, end, step) {
  var ylist1 = [];
  for (let i = start; i < end; i++) {
    var y1 = i + "-" + (i + 1);
    ylist1.push(y1);
  }
  return ylist1;
}

export default function FormDialog(props) {
  const classes = useStyles();
  const [Acadarray, setAcadarray] = React.useState([]);
  const [title, setTitle] = React.useState(null);
  const [year11, setY1] = React.useState(null);
  const [year12, setY2] = React.useState(null);
  const [isUpdate, setUpdate] = React.useState(false);
  const year1 = Array.from(range(2015, 2030, 1));
  const { sid } = useParams();
  const [Darray, setDarray] = React.useState(null);

  const handleClose = () => {
    props.close();
  };
  useEffect(() => {
    axios
      .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
        ["fun_name"]: "getAcademicId",
        ["sid"]: sid,
      })
      .then((res) => {
        console.log(res.data.academic_year);
        setAcadarray(res.data.academic_year);
      });
  }, []);
  const Save = () => {
    if (props.Ori != undefined && year11.academics != null) {
      var dt = sessionStorage.getItem("design");
      console.log(year11.academics + "-->" + year11.acadid);
      axios
        .post("https://topschool.prisms.in/rest/index.php/user_list.json?", {
          ["fun_name"]: "InsertDesign",
          ["sid"]: sid,
          ["name"]: year11.academics + "-" + title,
          ["acadid"]: year11.acadid,
          ["design"]: dt,
          ["ori"]: props.Ori,
        })
        .then((res) => {
          window.alert("Successfully Saved the design");
        });
    }
  };
  const Update = () => {
    setUpdate(true);
  };
  useEffect(() => {}, []);

  const y1change = (event) => {
    setY1(event.target.value);
  };
  const handleUpdClose = () => {
    setUpdate(false);
    props.close();
  };
  const handledClose = () => {
    setUpdate(false);
  };
  return isUpdate === false ? (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Save New Design</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Save the new design enter the title and click on save button.
          </DialogContentText>
          <div>
            <FormControl className={classes.formControl}>
              <InputLabel>Acad Year</InputLabel>
              <Select
                value={year11}
                onChange={y1change}
                displayEmpty
                className={classes.selectEmpty}
              >
                {Acadarray != null && Acadarray != undefined
                  ? Acadarray.map((v, i) => (
                      <MenuItem key={i} value={v}>
                        {v.academics}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
            <p
              style={{ display: "inline-block", marginTop: 30, marginLeft: 20 }}
            >
              -
            </p>
            <TextField
              style={{ marginLeft: 20, display: "inline-block", width: "18vw" }}
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              type="name"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => Save()} color="primary">
            Save new design
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <UpdateD
      close={handleUpdClose}
      clear={props.clear}
      ld={props.ld}
      savenew={handledClose}
    />
  );
}
